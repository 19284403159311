import { action, computed, observable } from "mobx"
import ConfirmationModal from "../components/ConfirmationModal"
import * as React from "react"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import AuthStore from "./AuthStore"
import { route } from "../routes/routes"

const uuid4 = require('uuid/v4')

type BadgeCountsType = {
  unreadMessageCount: number
}

class AppStateStore {
  @observable private modalSpinnerCount = 0
  @observable private contentHeaderRenderer?: () => React.ReactNode = undefined
  @observable public badgeCounts: BadgeCountsType = {
    unreadMessageCount: 0,
  }

  fetchBadgeCounts = () => {
    // ApiClient.getInstance().get(ApiRoutes.getBadgeCounts)
    //   .then(response => {
    //     this.badgeCounts.unreadMessageCount = response.data.unread_message_count
    //   })

    ApiClient.getInstance().get(route(ApiRoutes.messageCount, { userId: AuthStore.getUser()!.id }))
      .then(response => {
        this.badgeCounts.unreadMessageCount = response.data.unread_message_count
      })
      .catch(err => {
        console.log('err')
        console.log(err)
      })
  }

  @computed get isModalSpinnerVisible (): boolean {
    return this.modalSpinnerCount > 0
  }

  @action
  public showModalSpinner () {
    this.modalSpinnerCount++
  }

  @action
  public dismissModalSpinner () {
    this.modalSpinnerCount > 0 && this.modalSpinnerCount--
  }

  @observable public modals: { key: string, props: {} }[] = []

  @action
  public showConfirmationModal (title: string, message: React.ReactNode, onClick: (result: boolean, modal: ConfirmationModal) => void, props: { type?: string } = {}) {
    const modalProps = Object.assign({
      title: title,
      message: message,
      buttons: [
        {
          label: 'Yes',
          type: props.type === 'danger' ? 'danger' : 'primary',
          onClick: (m: ConfirmationModal) => onClick(true, m),
        },
        {
          label: 'No',
          type: 'secondary',
          onClick: (m: ConfirmationModal) => onClick(false, m),
        },
      ],
      show: true,
    }, props)

    this.modals.push({
      key: uuid4(),
      props: modalProps,
    })
  }

  public showAlertModal (title: string, message: React.ReactNode, onClick: (modal: ConfirmationModal) => void = m => {
    m.hide()
  }, props: {} = {}) {
    const modalProps = Object.assign({
      title: title,
      message: message,
      buttons: [
        {
          label: 'OK',
          type: 'primary',
          onClick: (m: ConfirmationModal) => onClick(m),
        },
      ],
      show: true,
    }, props)

    this.modals.push({
      key: uuid4(),
      props: modalProps,
    })
  }

  @action
  setContentHeaderRenderer (renderer?: () => React.ReactNode) {
    this.contentHeaderRenderer = renderer
  }

  renderContentHeader (): React.ReactNode | null {
    return this.contentHeaderRenderer ? this.contentHeaderRenderer() : null
  }
}

const appState = new AppStateStore()

export default appState as AppStateStore
