import axios, { AxiosPromise, AxiosRequestConfig, CancelTokenSource } from 'axios'
import Config from "../common/Config"
import AuthStore from "../stores/AuthStore"
import { compileRoutes, route } from "../routes/routes"
import { trimLeft, trimRight } from "../common/Util"
import * as qs from "qs"
import { DocumentLibraryDescriptor } from "../models/DocumentLibraryItem"
import Chapter from '../models/Chapter'

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.headers.post['Accept'] = 'application/json'

const AUTH_TOKEN_HEADER_KEY = 'X-AUTH-TOKEN'

export interface RequestOptions {
  cancelTokenSource?: CancelTokenSource
}

export type QueryWhereClause = {id: string, value: any} | {[column: string]: any} | {id: string, op: string, value: any} | {_scope: string, value?: any} | undefined
export type QueryOrderClause = {id: string, desc?: boolean} | string

export interface QueryOptions {
  where?: QueryWhereClause[]
  order?: QueryOrderClause[]
  offset?: number
  limit?: number
  returnTotal?: boolean
}

class ApiClient {
  getInstance = () => {
    let params = {
      maxContentLength: 10000,
      timeout: 30000,
    }

    const headers = {
      [AUTH_TOKEN_HEADER_KEY]: AuthStore.getAuthToken() || null,
      'X-API-VERSION': Config.API_VERSION,
    }

    return axios.create({
      ...params,
      baseURL: Config.API_URL,
      headers: headers,
    })
  }

  getImageBlob (url: string) {
    return axios.create().get(url, { responseType: "blob" });
  }

  authenticate (username: string, password: string) {
    return this.getInstance()
      .post(route(ApiRoutes.authenticate), {
        username,
        password,
      })
  }

  resetPasswordRequest (username: string) {
    return this.getInstance()
      .post(route(ApiRoutes.resetPasswordRequest), { username })
  }

  resetPassword (token: string, username: string, password: string, passwordConfirmation: string) {
    return this.getInstance()
      .post(route(ApiRoutes.resetPassword), { token, username, password, password_confirmation: passwordConfirmation })
  }

  getAuthenticatedUser (): AxiosPromise {
    return this.getInstance()
      .get(route(ApiRoutes.profile))
  }

  getSecureDownloadToken (): AxiosPromise {
    return this.getInstance()
      .get(route(ApiRoutes.secureDownloadToken))
  }

  query = (query: string, options: QueryOptions = {}, requestOptions: AxiosRequestConfig = {}) => {
    let { where, order, ...otherOptions } = options
    where = where ? where.filter(w => !!w) : undefined
    order = order ? order.filter(s => !!s) : undefined
    const queryObject = {
      ...otherOptions,
      where: where,
      order: order,
      query: query,
    }

    const config = Object.assign({}, { headers: { 'Content-Type': 'application/json' } }, requestOptions)

    return this.getInstance()
      .post('/query', JSON.stringify(queryObject), config)
  }

  regions = crudMethods(this, ApiRoutes.regions)
  areas = crudMethods(this, ApiRoutes.areas)
  categories = crudMethods(this, ApiRoutes.categories)
  supportAreas = {
    ...crudMethods(this, ApiRoutes.supportAreas),
    destroy: (supportAreaId: number) => {
      return this.getInstance()
        .delete(route(ApiRoutes.supportAreas.destroy, { id: supportAreaId }))
    },
    getSupportAreaChapters: (supportAreaId: number) => {
      return this.getInstance()
        .get(route(ApiRoutes.supportAreas.getSupportAreaChapters, { id: supportAreaId }))
    },
    getSupportAreaUsers: (supportAreaId: number) => {
      return this.getInstance()
        .get(route(ApiRoutes.supportAreas.getSupportAreaUsers, { id: supportAreaId }))
    },
    addChapter: (supportAreaId: number, chapterId: number) => {
      return this.getInstance()
        .post(route(ApiRoutes.supportAreas.addChapter, { id: supportAreaId, chapterId: chapterId }))
    },
    removeChapter: (supportAreaId: number, chapterId: number) => {
      return this.getInstance()
        .delete(route(ApiRoutes.supportAreas.removeChapter, { id: supportAreaId, chapterId: chapterId }))
    },
    addUser: (supportAreaId: number, userId: number, data: {title: string}) => {
      return this.getInstance()
        .post(route(ApiRoutes.supportAreas.addUser, { id: supportAreaId, userId: userId }), data)
    },
    removeUser: (supportAreaId: number, userId: number) => {
      return this.getInstance()
        .delete(route(ApiRoutes.supportAreas.removeUser, { id: supportAreaId, userId: userId }))
    },
  }
  chapters = {
    ...crudMethods(this, ApiRoutes.chapters),
    getMemberPositions: (chapterId: number) => {
      return this.getInstance()
        .get(route(ApiRoutes.chapters.getMemberPositions, { id: chapterId }))
    },
    setMemberPosition: (chapterId: number, positionId: number, data: {}) => {
      return this.getInstance()
        .put(route(ApiRoutes.chapters.setMemberPosition, { id: chapterId, positionId: positionId }), data)
    },
    clearMemberPosition: (chapterId: number, memberPositionId: number) => {
      return this.getInstance()
        .delete(route(ApiRoutes.chapters.clearMemberPosition, { id: chapterId, memberPositionId }))
    },

    getIncomingMemberPositions: (chapterId: number) => {
      return this.getInstance()
      .get(route(ApiRoutes.upcomingPositions.get, { id: chapterId }))
    },
    setIncomingMemberPosition: (chapterId: number, positionId: number, data: {}) => {
      return this.getInstance()
        .put(route(ApiRoutes.chapters.addIncomingPositions, { id: chapterId, positionId: positionId }), data)
    },
    clearIncomingMemberPosition: (chapterId: number, memberPositionId: number) => {
      return this.getInstance()
        .delete(route(ApiRoutes.chapters.clearIncomingPositions, { id: chapterId, memberPositionId }))
    },

    updateIncomingMemberPosition: (chapterId: number) => {
      return this.getInstance()
        .put(route(ApiRoutes.chapters.updateIncomingBoardMembers, { id: chapterId }))
    },

    updateChapterCoverPhoto: (chapterId: number, file: File) => {
      return this.getInstance()
        .post(route(ApiRoutes.chapters.updateChapterCoverPhoto, {id:chapterId,file:file}))
    },

    updateChapterMainPhoto: (chapterId: number, file: File, ) => {
      return this.getInstance()
        .post(route(ApiRoutes.chapters.updateChapterMainPhoto, {id:chapterId,file:file}))
    },

    addPaymentMethod: (chapterId: number, data: {}) => {
      return this.getInstance()
        .post(route(ApiRoutes.chapters.addPaymentMethod, { id: chapterId }), data)
    },
    setDefaultPaymentMethod: (chapterId: number, paymentMethodId: number) => {
      return this.getInstance()
        .put(route(ApiRoutes.chapters.setDefaultPaymentMethod, { id: chapterId, paymentMethodId: paymentMethodId }))
    },
    removePaymentMethod: (chapterId: number, paymentMethodId: number) => {
      return this.getInstance()
        .delete(route(ApiRoutes.chapters.removePaymentMethod, { id: chapterId, paymentMethodId: paymentMethodId }))
    },
  }
  contactMethods = {
    add: (contactableType: string, contactableId: number, contactMethodType: string, data: {}) => {
      return this.getInstance()
        .post(route(ApiRoutes.contactMethods.add, { contactableType, contactableId, contactMethodType }), data)
    },
    remove: (contactableType: string, contactableId: number, contactMethodId: number) => {
      return this.getInstance()
        .delete(route(ApiRoutes.contactMethods.remove, { contactableType, contactableId, contactMethodId }))
    },
    order: (contactableType: string, contactableId: number, contactMethodType: string, data: {}) => {
      return this.getInstance()
        .post(route(ApiRoutes.contactMethods.order, { contactableType, contactableId, contactMethodType }), data)
    },
  }
  fileUploads = {
    remove: (fileUploadId: number) => {
      return this.getInstance()
        .delete(route(ApiRoutes.fileUploads.remove, { id: fileUploadId }))
    }
  }
  members = {
    ...crudMethods(this, ApiRoutes.members),
    checkDuplicates: (data: {}) => {
      return this.getInstance()
        .get(route(ApiRoutes.members.checkDuplicates, {}, data))
    },
    updateShippingAddress: (memberId: number, data: {}) => {
      return this.getInstance()
        .post(route(ApiRoutes.members.updateShippingAddress, { id: memberId }), data)
    },
    updateBiography: (memberId: number, data: {}) => {
      return this.getInstance()
        .post(route(ApiRoutes.members.updateBiography, { id: memberId }), data)
    },
    updateProfileImage: (memberId: number, data: {}) => {
      return this.getInstance()
        .post(route(ApiRoutes.members.updateProfileImage, { id: memberId }), data)
    },
    updateBusinessInfo: (memberId: number, data: {}) => {
      return this.getInstance()
        .post(route(ApiRoutes.members.updateBusinessInfo, { id: memberId }), data)
    },
    updateBusinessDescription: (memberId: number, data: {}) => {
      return this.getInstance()
        .post(route(ApiRoutes.members.updateBusinessDescription, { id: memberId }), data)
    },
    updateBusinessAddress: (memberId: number, data: {}) => {
      return this.getInstance()
        .post(route(ApiRoutes.members.updateBusinessAddress, { id: memberId }), data)
    },
    updateBusinessHours: (memberId: number, data: {}) => {
      return this.getInstance()
        .post(route(ApiRoutes.members.updateBusinessHours, { id: memberId }), data)
    },
    updateBusinessLogo: (memberId: number, data: {}) => {
      return this.getInstance()
        .post(route(ApiRoutes.members.updateBusinessLogo, { id: memberId }), data)
    },
    updateTestimonyText:(memberId: number, data: {}) => {
        return this.getInstance()
        .post(route(ApiRoutes.members.updateTestimonyText, { id: memberId }), data)
    },
    addPaymentMethod: (memberId: number, data: {}) => {
      return this.getInstance()
        .post(route(ApiRoutes.members.addPaymentMethod, { id: memberId }), data)
    },
    getPaymentMethods: (memberId: number) => {
      return this.getInstance()
        .get(route(ApiRoutes.members.getPaymentMethods, { id: memberId }))
    },
    setDefaultPaymentMethod: (memberId: number, paymentMethodId: number) => {
      return this.getInstance()
        .put(route(ApiRoutes.members.setDefaultPaymentMethod, { id: memberId, paymentMethodId: paymentMethodId }))
    },
    removePaymentMethod: (memberId: number, paymentMethodId: number) => {
      return this.getInstance()
        .delete(route(ApiRoutes.members.removePaymentMethod, { id: memberId, paymentMethodId: paymentMethodId }))
    },
    getMemberships: (memberId: number) => {
      return this.getInstance()
        .get(route(ApiRoutes.members.getMemberships, { id: memberId }))
    },
    createMembership: (memberId: number, data: {}) => {
      return this.getInstance()
        .post(route(ApiRoutes.members.createMembership, { id: memberId }), data)
    },
    setMembershipAutoRenewal: (memberId: number, membershipId: number, autoRenew: boolean) => {
      const r: string = route(ApiRoutes.members.setMembershipAutoRenewal, { id: memberId, membershipId: membershipId });
      if (autoRenew) {
        return this.getInstance()
          .put(r)
      } else {
        return this.getInstance()
          .delete(r)
      }
    },
    updateMembership: (memberId: number, membershipId: number, data: {}) => {
      return this.getInstance()
        .put(route(ApiRoutes.members.updateMembership, { id: memberId, membershipId: membershipId }), data)
    },
    terminateMembership: (memberId: number, data?: {}) => {
      return this.getInstance()
        .post(route(ApiRoutes.members.terminateMembership, { id: memberId }), data)
    },
    getMembershipFees: (memberId: number) => {
      return this.getInstance()
        .get(route(ApiRoutes.members.getMembershipFees, { id: memberId }))
    },
    renewMembership: (memberId: number, membershipId: number, data: {}) => {
      return this.getInstance()
        .post(route(ApiRoutes.members.renewMembership, { id: memberId, membershipId: membershipId }), data)
    },
  }
  users = {
    ...crudMethods(this, ApiRoutes.users),
    getSupportAreaUsers: (userId: number) => {
      return this.getInstance()
        .get(route(ApiRoutes.users.getSupportAreaUsers, { id: userId }))
    },
  }

  notices = {
    ...crudMethods(this, ApiRoutes.notices),
  }

  sentNotifications = {
    create: (data: {}) => this.getInstance()
      .post(route(ApiRoutes.sentNotifications.create), data)
  }

  ntsEvents = {
    ...crudMethods(this, ApiRoutes.ntsEvents),
  }

  boardTrainings = {
    ...crudMethods(this, ApiRoutes.boardTrainings),
  }

  tips = crudMethods(this, ApiRoutes.tips)

  guests = {
    ...crudMethods(this, ApiRoutes.guests),
    sendApplication: (guestId: number, message: string, sendToSourceMember: boolean) => {
      return this.getInstance()
        .post(route(ApiRoutes.guests.sendApplication, { id: guestId }), { message, sendToSourceMember })
    }
  }

  positions = {
    getPositions: () => {
      return this.getInstance()
        .get(route(ApiRoutes.positions.index))
    }
  }

  upcomingPositions = {
    get: (chapterId: number) => {
      return this.getInstance()
        .get(route(ApiRoutes.upcomingPositions.get, { id: chapterId }))
    }
  }

  testimonies = {...crudMethods(this, ApiRoutes.testimonies),}


  uploadDocument = (libraryDescriptor: DocumentLibraryDescriptor, document: File, parentId?: string, onUploadProgress?: (progressEvent: ProgressEvent) => void) => {
    const formData = new FormData()
    formData.append('file', document)
    libraryDescriptor.libraryOwnerType && formData.append('library_owner_type', libraryDescriptor.libraryOwnerType)
    libraryDescriptor.libraryOwnerId && formData.append('library_owner_id', String(libraryDescriptor.libraryOwnerId))
    formData.append('library_type', libraryDescriptor.libraryType)
    parentId && formData.append('parent_id', parentId)

    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    if (onUploadProgress) {
      config.onUploadProgress = onUploadProgress
    }

    return this.getInstance()
      .post(route(ApiRoutes.documentLibrary.uploadDocument), formData, config)
  }

  // **** ASYNC HELPERS ****
  getRegionOptions = asyncOptionsMethod(this, ApiRoutes.async.regionOptions)
  getPositionOptions = asyncOptionsMethod(this, ApiRoutes.async.positionOptions)
  getRegionOptionsForAdmin = asyncOptionsMethod(this, ApiRoutes.async.regionOptions, { 'scope_admin': 1 })
  getAreaOptions = asyncOptionsMethod(this, ApiRoutes.async.areaOptions)
  getAreaOptionsForAdmin = asyncOptionsMethod(this, ApiRoutes.async.areaOptions, { 'scope_admin': 1 })
  getMeetingDayOptions = asyncOptionsMethod(this, ApiRoutes.async.meetingDayOptions)
  getMeetingTimeOptions = asyncOptionsMethod(this, ApiRoutes.async.meetingTimeOptions)
  getChapterOptions = asyncOptionsMethod(this, ApiRoutes.async.chapterOptions)
  getChapterOptionsFiltered = (options: {
    areaId?: number
    state?: string
  }) => asyncOptionsMethod(this, ApiRoutes.async.chapterOptions, options)
  getChapterOptionsForAdmin = asyncOptionsMethod(this, ApiRoutes.async.chapterOptions, { 'scope_admin': 1 })
  getChapterStateOptions = asyncOptionsMethod(this, ApiRoutes.async.chapterStateOptions)
  getChapterStateOptionsFiltered = (options: {areaId?: number}) => asyncOptionsMethod(this, ApiRoutes.async.chapterStateOptions, options)
  getCategoryOptions = (chapterId?: number) => asyncOptionsMethod(this, ApiRoutes.async.categoryOptions, { chapter: chapterId })
  getMemberOptions = (chapterId?: number) => asyncOptionsMethod(this, ApiRoutes.async.memberOptions, { chapter: chapterId })
  getGuestOptions = (chapterId?: number, categoryId?: number) => asyncOptionsMethod(this, ApiRoutes.async.guestOptions, { chapter: chapterId, category: categoryId })
  getRecurringMonthOptions = (date?: string) => asyncOptionsMethod(this, ApiRoutes.async.recurringOptionsMonth, { recurring_starts_on: date })

}

const asyncOptionsMethod = (apiClient: ApiClient, apiRoute: string, query?: {}) => {
  return (search: string, requestOptions: RequestOptions = {}) => {
    const config: AxiosRequestConfig = {}

    if (requestOptions.cancelTokenSource) {
      config.cancelToken = requestOptions.cancelTokenSource.token
    }

    return apiClient.getInstance()
      .get(route(apiRoute, undefined, { ...query, q: search }), config)
  }
}

export type CrudMethods = {
  index: (params: {}, requestOptions: RequestOptions) => AxiosPromise
  show: (id: number) => AxiosPromise
  create: (data: {}) => AxiosPromise
  update: (id: number, data: {}) => AxiosPromise
}

const crudMethods = (apiClient: ApiClient, routes: {
  index: string,
  store: string,
  update: string,
  show: string,
}) => {
  return {
    index: (params: {} = {}, requestOptions: RequestOptions = {}) => {
      const config: AxiosRequestConfig = {}

      if (requestOptions.cancelTokenSource) {
        config.cancelToken = requestOptions.cancelTokenSource.token
      }

      return apiClient.getInstance()
        .get(route(routes.index, {}, params), config)
    },

    show: (id: number) => {
      return apiClient.getInstance()
        .get(route(routes.show, { id }))
    },

    create: (data: {}) => {
      return apiClient.getInstance()
        .post(route(routes.store), data)
    },

    update: (id: number, data: {}) => {
      return apiClient.getInstance()
        .put(route(routes.update, { id }), data)
    }
  }
}

const crudApiRoutes = (base: string) => {
  return {
    _options: {
      prefix: `/${base}`,
    },
    index: '',
    store: '/store',
    update: '/:id',
    show: '/:id',
  }
}

export function apiRoute (path: string, query: {} = {}) {
  let url = [trimRight(Config.API_URL || '', ['/']), trimLeft(path, ['/'])].join('/')

  if (Object.getOwnPropertyNames(query).length > 0) {
    url = `${url}${url.indexOf('?') === -1 ? '?' : '&'}${qs.stringify(query)}`
  }

  return url
}

export const ApiRoutes = {
  resetPasswordRequest: '/password/email',
  resetPassword: '/password/reset',
  authenticate: '/authenticate',
  impersonate: '/impersonate',
  secureDownloadToken: '/secure/download/token',
  printChapterRoster: '/printable-chapter-roster/:chapterId',
  profile: '/profile',
  getBadgeCounts: '/badge-counts',
  regions: crudApiRoutes('regions'),
  areas: crudApiRoutes('areas'),
  categories: crudApiRoutes('categories'),
  supportAreas: {
    ...crudApiRoutes('support-areas'),
    destroy: '/:id',
    getSupportAreaChapters: '/:id/support-area-chapters',
    getSupportAreaUsers: '/:id/support-area-users',
    addChapter: '/:id/chapters/:chapterId',
    removeChapter: '/:id/chapters/:chapterId',
    addUser: '/:id/users/:userId',
    removeUser: '/:id/users/:userId',
  },
  chapters: {
    ...crudApiRoutes('chapters'),
    getMemberPositions: '/:id/member-positions',
    setMemberPosition: '/:id/position/:positionId',
    clearMemberPosition: '/:id/member-position/:memberPositionId',
    topTippers: '/:id/top-tippers',
    rank: '/:id/rank',
    program: {
      _options: {
        prefix: '/:id/program',
      },

      addMember: '/members/:memberId',
      removeMember: '/members/:memberId',
      setOrder: '/members/:memberId',
      send: '/send',
      skipMeeting: '/skip-meeting',
    },
    checkCategoryAvailability: '/:id/category-availability/:categoryId',

    updateSettings: '/:id/settings',

    addPaymentMethod: '/:id/payment-method',
    setDefaultPaymentMethod: '/:id/payment-methods/:paymentMethodId/default',
    removePaymentMethod: '/:id/payment-methods/:paymentMethodId',
    setCurrentProgramSlot: '/:id/current-program-slot',
    updateChapterFees: '/:id/chapter-fees',
    addIncomingPositions: '/:id/incoming-position/:positionId',
    clearIncomingPositions: '/:id/incoming-member-position/:memberPositionId',
    updateIncomingBoardMembers: '/:id/update-current-board-member',
    updateChapterCoverPhoto: '/:id/update-chapter-cover-photo',
    fetchChapterCoverPhoto: '/:id/fetch-chapter-cover-photo',
    updateChapterMainPhoto: '/:id/update-chapter-main-photos',
  },
  contactMethods: {
    add: '/contact-methods/:contactableType/:contactableId/:contactMethodType',
    remove: '/contact-methods/:contactableType/:contactableId/:contactMethodId',
    order: '/contact-methods/:contactableType/:contactableId/:contactMethodType/order',
  },
  positions: {
    index: '/positions',
  },
  upcomingPositions: {
    get: '/chapters/:id/incoming-member-positions',
  },
  fileUploads: {
    remove: '/file-uploads/:id',
  },

  member: {
    _options: {
      prefix: '/member',
    },
    previewTemplate: '/email-templates/preview-template/:templateName',
    saveTemplate: '/email-templates/save-template/:templateName',
    sendPreview: '/email-templates/send-preview/:templateName',
    restoreToDefault: '/email-templates/restore-template/:templateName',
  },


  members: {
    ...crudApiRoutes('members'),
    checkDuplicates: '/check-duplicates',
    updateShippingAddress: '/:id/shipping-address',
    updateBiography: '/:id/biography',
    updateProfileImage: '/:id/profile-image',
    updateBusinessInfo: '/:id/business-info',
    updateBusinessDescription: '/:id/business-description',
    updateBusinessAddress: '/:id/business-address',
    updateBusinessHours: '/:id/business-hours',
    updateBusinessLogo: '/:id/business-logo',
    addPhoneNumber: '/:id/phone-number',
    removePhoneNumber: '/:id/phone-number/:phoneId',
    orderPhoneNumbers: '/:id/order-phone-numbers',
    addPaymentMethod: '/:id/payment-method',
    getPaymentMethods: '/:id/payment-methods',
    setDefaultPaymentMethod: '/:id/payment-methods/:paymentMethodId/default',
    removePaymentMethod: '/:id/payment-methods/:paymentMethodId',
    getMemberships: '/:id/memberships',
    createMembership: '/:id/membership',
    setMembershipAutoRenewal: '/:id/membership/:membershipId/auto-renewal',
    updateMembership: '/:id/membership/:membershipId',
    terminateMembership: '/:id/membership/terminate',
    getMembershipFees: '/:id/membership-fees',
    renewMembership: '/:id/membership/:membershipId/renew',
    updateBadgeColor: '/:id/badge-color',
    updateVideoUrl: '/:id/video-url',
    removeVideoUrl: '/:id/video-url',
    addSponsor: '/:id/sponsor',
    removeSponsor: '/:id/sponsor/:sponsorId',
    getNotificationSettings: '/:id/notification-settings',
    updateNotificationSetting: '/:id/notification-setting',
    addNotificationChannelDestination: '/:id/notification-destination',
    removeNotificationChannelDestination: '/:id/notification-destination/:destinationId',
    resendNotificationChannelDestinationVerification: '/:id/notification-destination/:destinationId/send-verification',
    markNotificationsRead: '/:id/notifications/mark-read',
    updateTimeZone: '/:id/timezone',
    copySponsors: '/:id/copy-sponsors',
    updateTestimonyText: '/:id/testimony-text',

    removeSponsorCredit: '/sponsor-credits/:id',
    updateSponsorCredits: '/:id/sponsor-credits',
    applySponsorCredit: '/:id/apply-sponsor-credit',

    updateSettings: '/:id/settings',
    generateRenewalInvoice: '/:id/generate-renewal-invoice',

    newsletter: {
      getSubscriptionStatus: '/:id/newsletter/subscription',
      subscribeToNewsletter: '/:id/newsletter/subscription',
      unsubscribeFromNewsletter: '/:id/newsletter/subscription',
    },

    sponsorStats: '/:id/sponsor-stats',
    usageStats: '/:id/usage-stats',
    assignToChapter: '/:id/chapter',
    assignToCategory: '/:id/category',

    requestResignation: '/:id/request-resignation',
    sendLoginInfo: '/:id/send-login-info',
    sendWelcomeLetter: '/:id/send-welcome-letter',
    updateApplicationStatus: '/:id/application-status',
    updateCompanyReplacement: '/:id/company-replacement',
    getCategoryChangeData: '/:id/get-category-change-data',
    requestCategoryChange: '/:id/request-category-change',
    getChapterTransferData: '/:id/get-chapter-transfer-data',
    requestChapterTransfer: '/:id/request-chapter-transfer',
    requestCompanyReplacement: '/:id/request-company-replacement',

    shippingRecipients: '/:id/shipping-recipients'

  },
  users: {
    ...crudApiRoutes('users'),
    getSupportAreaUsers: '/:id/support-area-users',
    updatePassword: '/:id/password',
  },
  notices: {
    ...crudApiRoutes('notices'),
    dismiss: '/:id/dismiss',
  },
  sentNotifications: {
    create: '/sent-notifications/store',
  },
  ntsEvents: {
    ...crudApiRoutes('nts-events'),
    destroy: '/:id',
    sendReminder: '/:id/send-reminder',
    sendMissedNotice: '/:id/send-missed',
    sendCancellationNotice: '/:id/send-cancelled',
    schedule: '/schedule',
    unschedule: '/unschedule',
    updateAttendance: '/update-attendance',
    removeMember: '/remove-member',
  },
  boardTrainings: {
    ...crudApiRoutes('board-training-events'),
    destroy: '/:id',
    sendReminder: '/:id/send-reminder',
    sendMissedNotice: '/:id/send-missed',
    sendCancellationNotice: '/:id/send-cancelled',
    schedule: '/schedule',
    unschedule: '/unschedule',
    updateAttendance: '/update-attendance',
    removeMember: '/remove-member',
  },
  tips: {
    ...crudApiRoutes('tips'),
    stats: '/stats',
    printTips: '/print',
    batches: {
      _options: {
        prefix: '/batches',
      },
      store: '/store',
      update: '/:id',
      destroy: '/:id',
    },
    assign: '/assign/:id',
    assignFrom: '/assign-from/:id',
    destroy: '/:id',
  },
  extendedNetwork: {
    _options: {
      prefix: '/extended-network',
    },

    sendInvitation: 'send-invitation',
    resendLink: 'resend-link/:id',
    revokeInvitation: 'revoke-invitation/:id',
    assignCategory: 'assign-category',
    unassignCategory: 'unassign-category',
    hideCategory: 'hide-category',
    showCategory: 'show-category',
  },
  guests: {
    ...crudApiRoutes('guests'),
    sendApplication: '/:id/send-application',
    inviteToMeeting: '/:id/invite-to-meeting',
    archive: ':id/archive',
  },
  attendance: {
    _options: {
      prefix: '/attendance',
    },

    setMeetingAttendeeStatus: '/events/:eventId/attendees/:memberId/status',
    setEventGuestAttendance: '/event-guests/:id/attendance',
    setUnsetMeetingAttendeeStatuses: '/events/:id/meeting-attendee/set-unset-status',
    setUnsetEventGuestAttendance: '/events/:id/event-guest/set-unset-attendance'
  },
  board: {
    boardDocuments: {
      _options: {
        prefix: '/sign-board-document',
      },

      show: '/:slug',
      sign: '/:slug/sign',
    },
  },
  public: {
    getApplication: '/application/:slug',
    submitApplication: '/application/:slug',
    acceptEventInvitation: '/event-invitation/:slug/accept',
    declineEventInvitation: '/event-invitation/:slug/decline',
    invoice: {
      _options: {
        prefix: '/invoice',
      },

      show: '/:slug',
      pay: '/:slug/payment',
    },
    paya: {
      _options: {
        prefix: '/paya',
      },

      addPaymentMethod: '/add-payment-method',
    },
    verifyNotificationDestination: '/verify-notification-destination/:slug',
  },
  async: {
    _options: {
      prefix: '/async',
    },

    regionOptions: '/regions',
    positionOptions: '/positions',
    areaOptions: '/areas',
    meetingDayOptions: '/meeting-days',
    meetingTimeOptions: '/meeting-times',
    chapterOptions: '/chapters',
    categoryOptions: '/categories',
    memberOptions: '/members',
    guestOptions: '/guests',
    chapterStateOptions: '/chapter-states',
    recurringOptionsMonth: '/recurring-options/month',
  },
  applications: {
    getVeteranDiscountData: '/applications/veteran-discount-data',
    getPaymentPlanData: '/applications/:id/payment-plan-data',
    approve: '/applications/:id/approve',
    deny: '/applications/:id/deny',
    archive: '/applications/:id/archive',
  },
  categoryChangeRequests: {
    approve: '/category-change-requests/:id/approve',
    deny: '/category-change-requests/:id/deny',
    archive: '/category-change-requests/:id/archive',
  },
  chapterTransferRequests: {
    approve: '/chapter-transfer-requests/:id/approve',
    deny: '/chapter-transfer-requests/:id/deny',
    archive: '/chapter-transfer-requests/:id/archive',
  },
  calendar: {
    _options: {
      prefix: 'calendar',
    },
    createEvent: '/event',
    deleteEvent: '/event/:eventId',
    cancelEvent: '/event/:eventId/cancel',
    updateEvent: '/event/:eventId',
    fetchEvent: '/chapter-meeting-type/:id',
    createRecurringEvent: '/recurring-meeting',
    deleteRecurringEvent: 'recurring-meeting/:id',
    updateRecurringEvent: 'recurring-meeting/:id',
    fetchRecurringEvent:'recurring-meeting/',
    fetchRecurringEventById: 'recurring-meeting/:id'
  },

  shippingRequests: {
    _options: {
      prefix: 'shipping-requests',
    },
    markComplete: '/mark-complete',
    printLabels: '/print-labels',
    update: '/:id',
  },

  notes: {
    _options: {
      prefix: 'notes',
    },
    store: '/store',
  },

  boardMemberReports: {
    _options: {
      prefix: 'board-member-reports',
    },

    tipSummary: '/tip-summary',
  },

  documentLibrary: {
    _options: {
      prefix: 'document-library',
    },

    uploadDocument: '/documents',
    createFolder: '/folders',
    addVideo: '/videos',
    deleteLibraryItem: '/item/:id',
    renameLibraryItem: '/item/:id',
  },

  reporting: {
    _options: {
      prefix: '/reporting',
    },
    chapterTipReport: '/chapter-tip-report/:chapterId',
    quarterlyTipReport: '/quarterly-tip-report/:chapterId',
    weeklyTipReport: '/weekly-tip-report/:chapterId',
    chapterAttendanceReport: '/chapter-attendance-report/:chapterId',
    chapterGuestAttendanceReport: '/chapter-guest-attendance-report/:chapterId',
    chapterTipClosureReport: '/chapter-tip-closure-report/:chapterId',
    chapterTipInsideOutsideReport: '/chapter-tip-inside-outside-report/:chapterId',
    dailyRevenueReport: '/daily-revenue-report',
    dailyRevenueReport2: '/daily-revenue-report2',
    chapterTipMasterOverviewReport: '/chapter-tip-master-overview-report/:chapterId',
    chapterTipOpenReport: '/chapter-tip-open-report/:chapterId',
    chapterTippersStackedReport: '/chapter-tippers-stacked-report/:chapterId',
    memberLossReport: '/member-loss-report',
    renewalPaymentReport: '/renewal-payments-report/:chapterId',
  },

  transactions: {
    _options: {
      prefix: '/transactions',
    },
    issueRefund: '/:transactionId/refund',
    store: '/store'
  },

  invoices: {
    _options: {
      prefix: '/invoices',
    },
    store: '/store',
    applyAdjustment: '/:invoiceId/adjustment',
    payInvoice: '/:invoiceId/pay',
    send: '/:invoiceId/send',
  },

  membershipRenewals: {
    _options: {
      prefix: '/membership-renewals',
    },
    addExemption: '/:membershipRenewal/exemptions',
    cancelRenewal: '/:membershipRenewal/cancel',
  },

  renewalExemptions: {
    _options: {
      prefix: '/renewal-exemptions',
    },
    cancelExemption: '/:exemptionId/cancel',
  },

  messaging: {
    _options: {
      prefix: '/messaging',
    },
    sendMessage: '/message',
    sendReply: '/reply',
    markConversationRead: '/conversation/:conversationId/mark-read',
    deleteConversation:'/conversation/:conversationId'
  },

  systemMessages: {
    _options: {
      prefix: '/system-messages',
    },
    redeliver: '/redeliver/:messageId',
    previewTemplate: '/preview-template/:templateName',
    saveTemplate: '/save-template/:templateName',
    sendPreview: '/send-preview/:templateName',
  },

  emailTemplates: {
    _options: {
      prefix: '/email-templates',
    },
    restoreToDefault: '/restore-template/:templateName',
    sendPreview: '/send-preview/:templateName',
    saveTemplate: '/save-template/:templateName',
    previewTemplate: '/preview-template/:templateName',
  },

  dashboard: {
    _options: {
      prefix: '/dashboard',
    },
    newMemberships: '/new-memberships',
    resignedMemberships: '/resigned-memberships',
    totalMembers: '/total-members',
    guestInvitations: '/guest-invitations',
    applications: '/applications',
    renewals: '/renewals',
    newChapters: '/new-chapters',
    closedChapters: '/closed-chapters',
    totalChapters: '/total-chapters',
    chaptersStarted: '/chapters-started',
    membershipsGraph: '/memberships-graph',
    terminations: '/terminations',
  },

  boardDocuments: {
    _options: {
      prefix: '/board-documents',
    },

    create: '',
    get: '/:id',
    send: '/:id/send',
  },

  devTools: {
    _options: {
      prefix: '/dev-tools',
    },
    runCommand: '/run-command',
    calculateRenewalFee: '/calculate-renewal-fee',
  },

  legacy: {
    _options: {
      prefix: '/legacy',
    },
    payLink: '/pay',

    onboarding: {
      _options: {
        prefix: '/onboarding',
      },
      getData: '/:slug',
      addPaymentMethod: '/:slug/payment-method',
    }
  },
  testimonies: {
    ...crudApiRoutes('testimonies'),
    batches: {
      store: '/store',
    },

},
  unreadMessages:'/unread-message-count',
  messageCount:'/message-count/:userId'
}

compileRoutes(ApiRoutes)

export default new ApiClient()
